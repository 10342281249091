import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {WPService} from '../../core/services/w-p.service';
import {Lancamento} from '../models/lancamento.model';
import {NgbCarousel, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LeadService} from '../../core/services/lead.service';
import {ToastrService} from 'ngx-toastr';
import {NgbSlideEvent} from '@ng-bootstrap/ng-bootstrap/carousel/carousel';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MASKS} from 'ng-brazil';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-lancamento',
  templateUrl: './lancamento.component.html',
  styleUrls: ['./lancamento.component.css']
})
export class LancamentoComponent implements OnInit {

  lancamento: Lancamento;
  form: FormGroup;
  MASKS = MASKS;
  imgs: Array<object>;
  currentPlant = 'plant0';
  urlSafe: SafeResourceUrl;

  selectedImageIndex = 0;
  arrayOfArrays = []
  imagesGaleria = [];
  imageIndex = []
  showFlag = false;

  @ViewChild('plantas') plantas: NgbCarousel;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  };
  galeriaCustom: OwlOptions = {
    loop: true,
    margin: 20,
    dots: false,
    responsive: {
      0: {
        items: 1,
      },
      500: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
  };

  constructor(private route: ActivatedRoute, private lancamentoService: WPService, private formBuilder: FormBuilder,
              private modalService: NgbModal, private toastr: ToastrService, private service: LeadService, private sanitizer: DomSanitizer) {
  }


  showLightbox(index) {
    this.selectedImageIndex = index;
    this.showFlag = true;
  }

  closeEventHandler() {
    this.showFlag = false;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.lancamentoService.slug(params.slug).subscribe(value => {
        console.log(value);
        this.lancamento = value;
        this.lancamento.image = this.lancamento?.image?.replace('-1200x800', '');
        if (this.lancamento && this.lancamento.fields && this.lancamento.fields.planta) {
          this.imgs = this.lancamento.fields.planta.map(value1 => {
            return {
              image: value1.url,
              thumbImage: value1.url,
              title: value1.title
            };
          });
        }

        this.imagesGaleria = this.lancamento?.fields.galeria.galeria

          let tempArray:Array<any> = [];
          for(let i = 0; i < this.imagesGaleria.length; i++){
            if(tempArray.length === 2){
              this.arrayOfArrays.push(tempArray);
              tempArray = [];
            }
            tempArray.push(this.imagesGaleria[i]);
          }
          if (tempArray.length > 0) {
            this.arrayOfArrays.push(tempArray);
          }

        this.imageIndex = [{image: this.lancamento?.fields.galeria.imagem_em_pe.url}, ...this.lancamento?.fields.galeria.galeria.map(value1 => {
          return {image: value1.url};
        })];
        this.buildForm();
        try {
          window.scrollTo({left: 0, top: 0, behavior: 'smooth'});
        } catch (e) {
          window.scrollTo(0, 0);
        }

        if(this.lancamento?.fields?.video) {
          const ArrayUrlSafe:string[] = this.lancamento.fields.video.split('/')
          const urlEmbed = `https://www.youtube.com/embed/${ArrayUrlSafe[ArrayUrlSafe.length - 1]}`;

          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(urlEmbed);
        }
      });

    });
  }

  buildForm() {
    this.form = this.formBuilder.group({
      nome: [, Validators.required],
      email: [, [Validators.required, Validators.email]],
      telefone: [],
      mensagem: [],
    });
  }


  submitForm() {
    let text_message:string = '';

    if (this.lancamento) {
      text_message = "Imóvel: " + this.lancamento.title.rendered.replace(/ /g, '').replace('/', '-') + 
        "\nPreço: " + this.formatValue(this.lancamento.fields.preco) +
        "\nLink: " + window.location.href +
        "\n-------------------------------------------------\n" +
        this.form.get('mensagem').value;
    }

    const form = new FormData();
    form.append('nome', this.form.get('nome').value);
    form.append('email', this.form.get('email').value);
    form.append('telefone', this.form.get('telefone').value);
    form.append('mensagem', text_message);
    form.append('categoria', this.lancamento?.fields?.portfolio_header_repeater[0]?.texto);
    // console.log(form.get('mensagem'));
    this.service.sendToContactForm(form, 505).subscribe(value => {
      this.modalService.dismissAll();
      this.toastr.success('Contato enviado!', 'Seus dados foram enviados com sucesso!');
      this.form.reset();
    });
  }

  open(content) {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      // @ts-ignore
      size: 'md',
      centered: true
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  whatsapp() {

    window.open('https://api.whatsapp.com/send?text=http://nextsim.com.br/imoveis/lancamentos/' + this.lancamento.slug, '_blank');

    // https://api.whatsapp.com/send?phone=1996099999&text=https://postcron.com/en/blog/landings/whatsapp-link-generator/#page-block-he6t7wyxoh
  }

  changePlanta(index: number) {
    this.plantas.select('plant' + index);

  }

  onSlide(slideEvent: NgbSlideEvent) {
    this.currentPlant = slideEvent.current;
  }

  formatValue(value:string | number):string{
    let bla:number = typeof(value) === 'string' ? Number(value) : value;

    return new Intl.NumberFormat('pt-BR',{style: 'currency', currency: 'BRL'}).format(bla);
  }

}
