<div class="dropdown-custom">
  <div class="dropdown-style">
    <div class="dropdown-items" *ngIf="bairros.length > 0">
      <h6>Bairros</h6>
      <div class="flex-column" *ngFor="let bairro of bairros">
        <div class="dropdown-options" (click)="changeValue($event, bairro.value)">
          <!-- <input type="checkbox"> -->
          <label for="" [title]="bairro.value">
            {{ bairro.value.toLowerCase() }}
          </label>
        </div>
      </div>
    </div>
    <div class="dropdown-items" *ngIf="cidades.length > 0">
      <h6>Cidades</h6>
        <div class="flex-column" *ngFor="let cidade of cidades">
          <div class="dropdown-options" (click)="changeValue($event, cidade.value)">
            <!-- <input type="checkbox" id=""> -->
            <label for="" [title]="cidade.value">
              {{ cidade.value.toLowerCase() }}
            </label>
          </div>
        </div>
    </div>
    <div class="dropdown-items" *ngIf="condominios.length > 0">
      <h6>Condomínios</h6>
      <div class="flex-column" *ngFor="let condominio of condominios">
        <div class="dropdown-options" (click)="changeValue($event, condominio.value)">
          <!-- <input type="checkbox" id=""> -->
          <label for="" [title]="condominio.value">
            {{ condominio.value.toLowerCase() }}
          </label>
        </div>
      </div>
    </div>
    <div class="dropdown-items" *ngIf="ruas.length > 0">
      <h6>Ruas</h6>
      <div class="flex-column" *ngFor="let rua of ruas">
        <div class="dropdown-options" (click)="changeValue($event, rua.value)">
          <!-- <input type="checkbox" id=""> -->
          <label for="" [title]="rua.value">
            {{ formatedStreet(rua.value) }}
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
