import { Component, Input, OnInit, SimpleChanges, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CustomDropdownComponent implements OnInit {

  @Input() cidades: Array<{ type: string; value: string, locacao: boolean, venda: boolean }> = [];
  @Input() bairros: Array<{ type: string; value: string, locacao: boolean, venda: boolean }> = [];
  @Input() condominios: Array<{ type: string; value: string, locacao: boolean, venda: boolean }> = [];
  @Input() ruas: Array<{ type: string; value: string, locacao: boolean, venda: boolean }> = [];


  @Output() changeValueEmit = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  private toCapitalizeCase(val: string): string {
    const street: string = this.formatedStreet(val); 
    const texts: string[] = street.split(' ');
    const formated: string[] = [];
    for (let i = 0; i < texts.length; i++){
      let text: string = '';
      for (let j = 0; j < texts[i].length; j++){
        if (j === 0){
          text += texts[i][j].toUpperCase();
        } else {
          text += texts[i][j].toLowerCase();
        }
      }
      formated.push(text);
    }

    return formated.join(' ');
  }

  formatedStreet(val: string): string {
    return val.replace(/^rua\s+/i, "").replace(/^Rua\s+/i, '').replace(/^Rua:\s+/i, '').replace(/^Rua.\s+/i, '').replace(/^RUA\s+/i, '').toLocaleLowerCase();
  }

  changeValue(event: any, value: string): void {
    this.changeValueEmit.emit(this.toCapitalizeCase(value));
  }

}
