
import {Component, ElementRef, ChangeDetectorRef, HostListener, OnInit, Renderer2, ViewChild, ViewEncapsulation} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {NgbDropdown, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AllImoveis} from '../core/services/all-imoveis.service';
import {WPService} from '../core/services/w-p.service';
import {PATH_AUTOCOMPLETE} from '../core/utils/constants.util';
import {doc, docSnapshots, Firestore} from '@angular/fire/firestore';
import {map} from 'rxjs/operators';
import {CustomSearchComponent} from '../core/components/custom-search/custom-search.component';
import * as _ from 'lodash';
import {MASKS} from 'ng-brazil';
import { CustomDropdownComponent } from '../core/components/custom-dropdown/custom-dropdown.component';
import IAutoComplete from '../core/types/IAutoComplete';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {

  autocompletes: IAutoComplete[] = [];

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private allImoveis: AllImoveis,
    private firestore: Firestore,
    private lancamentoService: WPService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && event.url.includes('/imoveis')) {
        this.rootView = false;
        this.simpleSearch = {
          finalidade: null,
          categoria: null,
          campo: null,
          autocomplete: null
        };
      } else if (event instanceof NavigationEnd && ((event.url.includes('/sobre-nos') ||
        event.url.includes('/quero-negociar') || event.url.includes('/blog') || event.url.includes('/servicos') || event.url.includes('/politica-de-cookies')))) {
        this.rootView = false;
      } else if (event instanceof NavigationEnd && !event.url.includes('/imoveis')) {
        this.rootView = true;
      }
      this.showMobileMenu = false;

    });
  }

  rootView = true;

  simpleSearch = {
    finalidade: null,
    categoria: null,
    campo: null,
    autocomplete: null
  };

  @ViewChild('customDrop') customDropdown: CustomDropdownComponent;

  bairrosAndCidades: {
    ruas: Array<{ type: string; value: string, locacao: boolean, venda: boolean }>,
    condominios: Array<{ type: string; value: string, locacao: boolean, venda: boolean }>,
    bairros: Array<{ type: string; value: string, locacao: boolean, venda: boolean }>,
    cidades: Array<{ type: string; value: string, locacao: boolean, venda: boolean }>,
    visible: boolean
  } = {
    visible: false,
    bairros: [],
    cidades: [],
    ruas: [],
    condominios: [],
  };

  @ViewChild('inputTextRef', { static: false }) inputTextRef: ElementRef;
  @ViewChild('customDrop', { read: ElementRef }) customDropdownRef: ElementRef;


  @ViewChild('customSearch') customSearch: CustomSearchComponent;

  autocompleteSelected: any;


  showMobileMenu = false;
  modalMobileStep2 = false;

  mobileMenuAlugar = false;

  title = 'Sua melhor forma de acessar imóveis<br> de alto padrão com suporte.';
  image = 'https://adminnovo.nextsim.com.br/wp-content/themes/theme/img/house-bg.jpg';


  protected readonly MASKS = MASKS;

  changeValueFormCampo(newValue: string){
    this.simpleSearch = {
      ...this.simpleSearch,
      campo: newValue
    };
    this.bairrosAndCidades.visible = false;
  }
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if ( this.inputTextRef ){
      if ( event.target === this.inputTextRef.nativeElement ) {
        return;
      }
      if (
          this.customDropdownRef &&
          (event.target === this.customDropdownRef.nativeElement ||
          this.customDropdownRef.nativeElement.contains(event.target))
      ) {
        this.inputTextRef.nativeElement.focus();
        return;
      }

      this.bairrosAndCidades = {
        ...this.bairrosAndCidades,
        visible: false
      };
    }
    // if(this.inputTextRef){
    //   if(!this.inputTextRef.nativeElement) return;
    //   if(event.target === this.inputTextRef.nativeElement) return;
    //   if(
    //       this.customDropdownRef &&
    //       (!this.customDropdownRef.nativeElement.contains(event.target) ||
    //       event.target === this.customDropdownRef.nativeElement)
    //     ) {
    //     this.bairrosAndCidades = {
    //       ...this.bairrosAndCidades,
    //       visible: false
    //     }
    //     this.inputTextRef.nativeElement.focus()
    //   }
    // }
  }

  ngOnInit() {
    // this.loadDefaults();

    this.allImoveis.autocomplete$.subscribe((data) => {
      this.autocompletes = data; // Atualizando o array local
      this.cdr.detectChanges(); // Força o Angular a re-renderizar
    });
    // ADMIN title
    this.lancamentoService.header().subscribe(value => {
      if (value.acf.texto_home) {
        this.title = value.acf.texto_home;
      }
      if (value.acf.imagem_home) {
        this.image = value.acf.imagem_home;
      }
    });
  }

  onSelectAutoComplete(e: any, value: string): void {
    if (value === null || value.trim() === '') { return; }

    if (value.length >= 3){

      const lowerCase = value.toLowerCase();

      const cidades = this.filterLocales(lowerCase, 'cidade');
      const bairros = this.filterLocales(lowerCase, 'bairro');
      const condominios = this.filterLocales(lowerCase, 'condominio');
      const ruas = this.filterLocales(lowerCase, 'rua');

      this.bairrosAndCidades = {
        visible: (bairros.length > 0 || cidades.length > 0 || ruas.length > 0 || condominios.length > 0),
        bairros: Array.from(new Map(bairros.map(item => [item.value.toLowerCase(), item])).values()),
        cidades: Array.from(new Map(cidades.map(item => [item.value.toLowerCase(), item])).values()),
        condominios: Array.from(new Map(condominios.map(item => [item.value.toLowerCase(), item])).values()),
        ruas: Array.from(new Map(ruas.map(item => [item.value.toLowerCase(), item])).values())
      };
    } else {
      this.bairrosAndCidades = {
        visible: false,
        bairros: [],
        cidades: [],
        condominios: [],
        ruas: []
      };
    }

    this.autocompleteSelected = this.autocompletes.find(item => item.value === value);
  }

  private filterLocales(value: string, type: string): IAutoComplete[] {
    let filterCategorie: IAutoComplete[] = this.autocompletes;
    if (this.simpleSearch.categoria !== null) {
      filterCategorie = this.autocompletes.filter((imovel) => {
        if (this.simpleSearch.categoria === 'comprar'){
          return imovel.venda === true;
        } else {
          return imovel.locacao === true;
        }
      });
    }
    if (this.simpleSearch.finalidade){
      filterCategorie = this.autocompletes.filter((imovel) => imovel.finalidade === this.simpleSearch.finalidade);
    }
    return filterCategorie.filter(item =>
      item.type === type && item.value.toLowerCase().includes(value)
    ) || [];
  }


  open() {
    this.customSearch.showMe = true;
  }

  scroll() {
    document.getElementById('backdrop').scrollIntoView({behavior: 'smooth'});
  }


  searchAutocomplete(event: any) {
    const datalist = document.querySelector('datalist');
    if (this.simpleSearch.campo.length > 3) {
      datalist.id = 'dynmicUserIds';
    } else {
      this.autocompleteSelected = null;
      datalist.id = '';
    }

  }


  search(query?: any) {
    if (!this.autocompleteSelected?.type) {
      const f = this.autocompletes.find(v => v.value?.toUpperCase() === this.simpleSearch?.campo?.toUpperCase());
      if (f) {
        this.autocompleteSelected = f;
      }
    }
    if (this.autocompleteSelected?.type === 'sigla') {
      this.router.navigate(['imoveis', this.autocompleteSelected?.value]).then();
    } else if (this.simpleSearch.finalidade || this.simpleSearch.categoria || this.simpleSearch.campo || query) {
      this.router.navigate(['imoveis'], {
        queryParams: query || {
          finalidade: this.simpleSearch.finalidade,
          categoria: this.simpleSearch.categoria,
          query: this.simpleSearch.campo,
          autocomplete: this.autocompleteSelected?.type
        }
      });
    }
  }

  selectCategoria(value, comp: NgbDropdown) {
    comp.close();
    this.simpleSearch.categoria = value;

  }

  selectFinalidade(value, comp) {
    comp.close();
    this.simpleSearch.finalidade = value;

  }

  openModal(e, content) {
    e.preventDefault();
    e.stopPropagation();
    this.showMobileMenu = false;
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      // @ts-ignore
      modalDialogClass: 'client-modal',
      size: 'md',
      centered: true
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  closeModal = () => {
    this.modalService.dismissAll();
  }

  // private loadDefaults() {
  //
  //   docSnapshots(doc(this.firestore, `${PATH_AUTOCOMPLETE}/${PATH_AUTOCOMPLETE}`))
  //     .pipe(map((a) => {
  //       return a.data();
  //     }))
  //     .subscribe(strings => {
  //       this.autocompletes = this.autocompletes = _.unionBy(strings.autocomplete, 'value');
  //     });
  // }
}
